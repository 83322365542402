import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zhCN from './lang/zh-CN'
import enUS from './lang/en-US'
import store from "../store";
let locale;
switch (store.state.lang) {
    case 'zh_ch':
        locale = 'zhCN'
        break;
    case 'en':
        locale = 'enUS'
        break;
}

Vue.use(VueI18n);

const messages = {
    zhCN: zhCN,
    enUS: enUS,
};

const i18n = new VueI18n({
    locale: locale,
    messages,
});

export default i18n;