const locale = {
    'title': '火星币',
    'views.index.home': '首页',
    'views.index.blurb': '什么是火星币',
    'views.index.market': '市场行情',
    'views.index.join': '加入我们',
    'views.index.login': '会员登录',
    'views.index.whitePaper': '白皮书',
    'views.index.serviceAgreement': '平台服务协议',
    'views.index.marsAgreement': '火星协会',
    'views.index.email': '电子邮件',

    'views.index.home.title': '火星币·未来世界的门票',
    'views.index.home.text': '机遇·未来可期',

    'views.index.blurb.title': '什么是火星币',
    'views.index.blurb.title1': '定义:一种与元宇审概念融合的加密数字货币，旨在引领时代潮流。',
    'views.index.blurb.title2': '发行:于2024年12月24日在公链上限量发行10亿枚，预计发行价为7美元/权。',
    'views.index.blurb.definition':'定义',
    'views.index.blurb.definition-text':'一种与元宇审概念融合的加密数字货币，旨在引领时代潮流。',
    'views.index.blurb.definition-text1':'于2024年12月24日在公链上限量发行10亿枚，预计发行价为7美元/权。',
    'views.index.blurb.text': '功能:',
    'views.index.blurb.text1': '· 稀有百盒:可在MARSBOX商城内用于开启稀有盲盒、直接购买商品。',
    'views.index.blurb.text2': '· 资产兑换:可在2024年12 月24日后在app中领取你的key,兑换到数字货币交易所，初日成交价可达7美元枚',
    'views.index.blurb.text3': '· 结算体系:马斯克期望火星币在未来火星上建立的城市中作为货币结算体系，不仅可以兑换现金或升值，也是支付手段和投资交易的重要工具。',
    'views.index.blurb.text4': '· 获得方式:购买火星计划获得火星币。',
    'views.index.blurb.text5': '火星币不仅仅是一种支付手段，更是一种开启未来的钥匙。',
    'views.index.blurb.text6': '在这个数字化时代，火星市代表着无限的可能性，它不受地域、国界或货币政策的限制，为全球人们提供了一种新的金融自由。',
    'views.index.blurb.text7': '无论是用于支付、投资还是交易，火星币都将成为引领未来的数字资产，驱动着创新、变革和发展。',
    'views.index.blurb.text8': '随着技术的不断进步和社会的不断演变，火星币将开启无尽的探索之旅，为我们的世界带来前所未有的改变和进步。',

    'views.index.join.title1': '共同寻找',
    'views.index.join.title2': '我们的未来',
    'views.index.join.from.userName.placeholder': '请填写您的姓名',
    'views.index.join.tip.userName.verification': '请输入姓名！',
    'views.index.join.from.email.placeholder': '请填写您的邮箱',
    'views.index.join.tip.email.verification': '请输入正确的邮箱地址！',
    'views.index.join.from.button.title': '提交',
    'views.index.join.tip.title': '您的信息提交成功！',
    'views.index.join.tip.button.title': '返回首页',
    'views.index.join.tip.tipText': '秒后系统自动返回首页',

    'views.index.login.title': '未来之星',
    'views.index.login.from.loginName.placeholder': '请输入手机号',
    'views.index.login.tip.loginName.verification': '请输入正确的手机号！',
    'views.index.login.from.loginPassword.placeholder': '请输入密码',
    'views.index.login.tip.loginPassword.verification': '请输入正确的密码！',
    'views.index.login.from.button.title': '登录',
    'views.index.login.tip.signIn.success': '登录成功！',
    'views.index.login.balance.title': '您的火星币余额',
    'views.index.login.balance.currency': '币',
}

export default {
    ...locale
}