const locale = {
    'title': 'Mars coin', // 火星币
    'views.index.home': 'Home', // 首页
    'views.index.blurb': 'Blurb', // 什么是火星币
    'views.index.market': 'Market', // 市场行情
    'views.index.join': 'Join us', // 加入我们
    'views.index.login': 'login', // 会员登录
    'views.index.whitePaper': 'White Paper', // 白皮书
    'views.index.serviceAgreement': 'Service Agreement', // 平台服务协议
    'views.index.marsAgreement': 'Mars Society', // 火星协会
    'views.index.email': 'E-mail', // 电子邮件

    'views.index.home.title': 'Tickets for the Future World', // 火星币·未来世界的门票
    'views.index.home.text': 'Opportunities · Promising Future', // 机遇·未来可期

    'views.index.blurb.title': 'What is Mars coin', // 什么是火星币
    'views.index.blurb.title1': 'Definition: A cryptocurrency that integrates with the concept of Yuanyushen and aims to lead the trend of the times.', // 定义:一种与元宇审概念融合的加密数字货币，旨在引领时代潮流。
    'views.index.blurb.title2': 'Issuance: On December 24, 2024, a limited edition of 1 billion pieces will be issued on the public chain, with an expected issuance price of $7 per option.', // · 发行:于2024年12月24日在公链上限量发行10亿枚，预计发行价为7美元/权。
    'views.index.blurb.definition':'definition',//定义
    'views.index.blurb.definition-text':'A cryptocurrency integrated with the concept of Yuanxin, which aims to lead the trend of The Times.',//一种与元宇审概念融合的加密数字货币，旨在引领时代潮流。
    'views.index.blurb.definition-text1':'On December 24, 2024, a limited number of 1 billion pieces will be issued on the public chain, with an expected issue price of $7 / right.',//于2024年12月24日在公链上限量发行10亿枚，预计发行价为7美元/权。
    'views.index.blurb.text': 'Function:', // 功能:
    'views.index.blurb.text1': '· Rare Hundred Boxes: can be used to open rare blind boxes and directly purchase goods in the MARSBOX mall.', // · 稀有百盒:可在MARSBOX商城内用于开启稀有盲盒、直接购买商品。
    'views.index.blurb.text2': '· Asset Exchange: You can collect your key in the app after December 24, 2024, and exchange it for a digital currency exchange. The initial transaction price can reach 7 US dollars per piece', // · 资产兑换:可在2024年12 月24日后在app中领取你的key,兑换到数字货币交易所，初日成交价可达7美元枚
    'views.index.blurb.text3': '· Settlement System: Musk expects the Martian coin to serve as a currency settlement system in cities established on Mars in the future, not only for cash exchange or appreciation, but also as an important tool for payment and investment transactions.', // · 结算体系:马斯克期望火星币在未来火星上建立的城市中作为货币结算体系，不仅可以兑换现金或升值，也是支付手段和投资交易的重要工具。
    'views.index.blurb.text4': '· Obtaining method: Purchase the Mars program to obtain Mars coins.', // · 获得方式:购买火星计划获得火星币。
    'views.index.blurb.text5': 'Mars coin is not only a means of payment, but also a key to unlocking the future.', // 火星币不仅仅是一种支付手段，更是一种开启未来的钥匙。
    'views.index.blurb.text6': 'In this digital age, Mars represents infinite possibilities, as it is not limited by geography, borders, or monetary policy, providing a new financial freedom for people around the world.', // 在这个数字化时代，火星市代表着无限的可能性，它不受地域、国界或货币政策的限制，为全球人们提供了一种新的金融自由。
    'views.index.blurb.text7': 'Whether used for payment, investment, or trading, Mars coin will become a leading digital asset driving innovation, change, and development in the future.', // 无论是用于支付、投资还是交易，火星币都将成为引领未来的数字资产，驱动着创新、变革和发展。
    'views.index.blurb.text8': 'With the continuous advancement of technology and the continuous evolution of society, Mars coin will embark on an endless journey of exploration, bringing unprecedented changes and progress to our world.', // 随着技术的不断进步和社会的不断演变，火星币将开启无尽的探索之旅，为我们的世界带来前所未有的改变和进步。

    'views.index.join.title1': 'Searching Together', // 共同寻找
    'views.index.join.title2': 'Our future', // 我们的未来
    'views.index.join.from.userName.placeholder': 'Please fill in your name', //请填写您的姓名
    'views.index.join.tip.userName.verification': 'Please enter your name!', // 请输入姓名！
    'views.index.join.from.email.placeholder': 'Please fill in your email address', // 请填写您的邮箱
    'views.index.join.tip.email.verification': 'Please enter the correct email address!', // 请输入正确的邮箱地址！
    'views.index.join.from.button.title': 'Submit', // 提交
    'views.index.join.tip.title': 'Submitted successfully!', // 您的信息提交成功！
    'views.index.join.tip.button.title': 'Go home', // 返回首页
    'views.index.join.tip.tipText': 'Return to the homepage in seconds', // 秒后系统自动返回首页

    'views.index.login.title': 'The Future Star', // 未来之星
    'views.index.login.from.loginName.placeholder': 'Please enter your phone number', // 请输入手机号
    'views.index.login.tip.loginName.verification': 'Please enter the correct phone number!', // 请输入正确的手机号！
    'views.index.login.from.loginPassword.placeholder': 'Please input a password', // 请输入密码
    'views.index.login.tip.loginPassword.verification': 'Please enter the correct password!', // 请输入正确的密码！
    'views.index.login.from.button.title': 'Sign in', // 登录
    'views.index.login.tip.signIn.success': 'Login successful!',
    'views.index.login.balance.title': 'Your balance of Mars coins', // 您的火星币余额
    'views.index.login.balance.currency': 'currency', // 币
}

export default {
    ...locale
}