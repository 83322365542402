import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './locales';
import "@/assets/text/text.scss";
import 'amfe-flexible/index.js';
import '@/utils/rem';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    // 全局事件总线
    Vue.prototype.$bus = this;
  },
  render: h => h(App)
}).$mount('#app')