<template>
  <div id="app">
    <SlideTransition />
  </div>
</template>

<script>
import SlideTransition from "@/views/components/SlideTransition.vue";
export default {
  name: "App",
  components: {
    SlideTransition,
  },
  data() {
    return {
      transitionName: "",
    };
  },
  created() {
    document.title = this.$t('title');
  }
};
</script>

<style>
* {
  margin: 0px;
  padding: 0px;
}

html,
body,
#app {
  background: black;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: #ffffff;
}

button {
  background: none;
  border: none;
}
</style>