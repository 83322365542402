<!-- SlideTransition.vue -->
<template>
  <transition name="slide" >
    <!-- @wheel="handleScroll" -->
    <router-view></router-view>
  </transition>
</template>

<script>

export default {
  name: "SlideTransition",
  data() {
    return {
    };
  },
  methods: {
  
   
  },
  mounted() {
    
  },
  beforeDestroy() {

  },
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
  position: absolute;
  width: 100%;
}
</style>