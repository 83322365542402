import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'index',
  redirect: '/home',
  component: () => import('@/views/index.vue'),
  children: [{
      path: '/home',
      name: 'home',
      component: () => import('@/views/indexViews/home.vue'),
      meta: {
        index: 1
      }
    },
    // {
    //   path: '/blurb',
    //   name:'blurb',
    //   component: () => import('@/views/indexViews/blurb.vue'),
    //   meta:{
    //     index: 2
    //   }
    // },
    // {
    //   path: '/market',
    //   name:'market',
    //   component: () => import('@/views/indexViews/market.vue'),
    //   meta:{
    //     index: 3
    //   }
    // },
    // {
    //   path: '/join',
    //   name:'join',
    //   component: () => import('@/views/indexViews/join.vue'),
    //   meta:{
    //     index: 4
    //   }
    // },
    // {
    //   path: '/login',
    //   name:'login',
    //   component: () => import('@/views/indexViews/login.vue'),
    //   meta:{
    //     index: 5
    //   }
    // }
  ],

}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router